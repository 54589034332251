import { createAction, props } from '@ngrx/store';

import { Claim } from '@wam/shared';

export const loadClaimsParentHome = createAction('[Parent Home Page] Load Claims');

export const loadClaimsClaimsComponent = createAction('[Claims Component] Load Claims');

export const loadClaimsSuccess = createAction(
  '[Grant API] Load Claims Success',
  props<{ claims: Claim[] }>(),
);

export const loadClaimsFail = createAction(
  '[Grant API] Load Claims Fail',
  props<{ error: string }>(),
);

export const loadUpstartSharedClaims = createAction(
  '[Dashboards Component] Load Upstart Shared Claims',
);

export const loadUpstartSharedClaimsSuccess = createAction(
  '[Grant API] Load Upstart Shared Claims Success',
  props<{ upstartSharedClaims: Claim[] }>(),
);

export const addChildParentHome = createAction(
  '[Parent Popup] Add Child',
  props<{ authorization: string }>(),
);

export const addChildClaimsComponent = createAction(
  '[Claims Component] Add Child',
  props<{ authorization: string }>(),
);

export const addChildSuccess = createAction(
  '[Grant API] Add Child Success',
  props<{ claim: Claim }>(),
);

export const addChildFail = createAction('[Grant API] Add Child Fail', props<{ error: string }>());

export const updateSelectedChild = createAction(
  '[Selector Side Effect] Update Selected Child',
  props<{ claim: Claim }>(),
);

export const removeChild = createAction('[Parent Home] Remove child', props<{ claim: Claim }>());

export const removeChildSuccess = createAction(
  '[Grant API] Remove child success',
  props<{ claim: Claim }>(),
);

export const removeChildFail = createAction(
  '[Grant API] Remove child fail',
  props<{ error: string; claim: Claim }>(),
);

export const showSwitchProductDialog = createAction(
  '[Claim Side Effect] Show switch product dialog',
  props<{ isSharedUpstartClaim: boolean }>(),
);

export const checkIfItIsSharedUpstartClaim = createAction(
  '[Claim Side Effect] Check if it is shared upstart claim',
  props<{ authorization: string }>(),
);
